import { createRouter, createWebHashHistory } from 'vue-router'

const routes = [
  {
    path: '/',
    name: 'asideIndex',
    redirect: '/asklist',
    component: () => import('@/layout/asideIndex.vue'),
    children: [
      {
        path: '/asklist',
        component: () => import('@/views/askList.vue')
      }
    ]
  },
  {
    path: '/login',
    name: 'loginView',
    component: () => import('../views/loginView.vue')
  },
]

const router = createRouter({
  history: createWebHashHistory(),
  routes
})
export function useRouter() {
  return router
}
export default router
